import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whitetick from '../../assets/whiteTick.png'

 const Plans = () => {
  return (
    <div className="plans-container" id='Plans'>
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
       <div className="plans-header">
       <span className='stroke-text'>Ready To Start</span>
        <span>Your Journey </span>
        <span className='stroke-text'>Now With Us👀</span>
       </div>
       <div className="plans">
        {plansData.map((plan , i) =>(
            <div className='plan' key={i}> 
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price} </span>
            <div className="features">
                {plan.features.map((feature , i) =>(
                    <div className="feature" key={i}>
                         <img src={whitetick} alt="" />
                         <span key={i }>{feature}</span>
                    </div>
                )
                    )}

            </div>
            <div>
                <span>See More Benefits -> </span>
            </div>
           
           <button className='btn'>Join-Now</button>

            </div>
        )

         )}
       </div>

      </div>
  )
}
export default Plans;
 