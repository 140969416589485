import React, { useState } from 'react'
import './Testimonials.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArrow from '../../assets/rightArrow.png'
import rightArrow from '../../assets/leftArrow.png'


const Testimonials = () => {
    const [selected, setSelected] = useState(0);
    let tlength = testimonialsData.length;
    
    return (
        <div className="Testimonials" id='Testimonials'>
            <div className="left-t">
                <span>Testimonials</span>
                <span className='stroke-text'>What They</span>
                <span>Say About US ?🧐 </span>

                <span>{testimonialsData[selected].review}</span>

                <span>{testimonialsData[selected].name + " "}
                    <span>- {testimonialsData[selected].status}</span>
                </span>

            </div>
            <div className="right-t">
                <div></div>
                <div></div>
                <img src={testimonialsData[selected].image} alt="" />
                <div className="arrows">
                    <img onClick={() => (
                        selected===0 ? setSelected(tlength - 1)
                        :setSelected((prev) => prev - 1)
                    )}
                     src={rightArrow} alt="" /> 
                    <img onClick={() => (
                        selected===tlength -1? setSelected(0 )
                        :setSelected((prev) => prev + 1)
                    )}
                    src={leftArrow} alt="" />
                </div>
            </div>
        </div>
    )
}
export default Testimonials;



















