import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_n9bxrhr', 'template_9c0ilze', form.current, 'XvQxzrwGF8J49mhii')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <div className="join" id='Join'>
            <div className="left-j">
                <div>
                    <span className='stroke-text'>Ready TO </span>
                    <span> Level Up</span>
                </div>
                <div>
                    <span>Your Body  </span>
                    <span className='stroke-text'> With Us ?  💪 </span>
                </div> 
            </div>
            <div className="right-j">
                <form  ref={form} action="submit" onSubmit={sendEmail} className="email-container">
                    <input type="email" name='user-email' placeholder='Enter Your Email Address' />
                    <button className='btn-j btn'>Join Now  </button>
                </form>
            </div>
        </div>
    )
}
export default Join;
