import React from 'react'
import Logo from '../../assets/logo.png'
import './Header.css'
import { Link } from "react-scroll";

const Header = () => {
  return (

    <div className='header'>
      <div>
        <img src={Logo} alt="" className='logo' />
      </div>
      <ul className='header-menu' >
        <li><Link
          to='Hero'
          span={true}
          smooth={true}
          >Home</Link></li>
        <li><Link
          to='Testimonials'
          span={true}
          smooth={true}
          >Programs</Link></li>
        <li><Link
          to='Reasons'
          span={true}
          smooth={true}
          >Why Us?</Link></li>
        <li>
        <Link
          to='Plans'
          span={true}
          smooth={true}
          >Plans</Link>
        </li>
        <li>
          <Link
          to='Testimonials'
          span={true}
          smooth={true}
          >Testimonials</Link>
        </li>
      </ul>





    </div>
  )
}
export default Header;
