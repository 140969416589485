import React from 'react'
import Header from '../Header/Header';
import './Hero.css'
import Hero_image from "../../assets/hero_image.png"
import Hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import hero_image from "../../assets/hero_image.png"
import { motion } from 'framer-motion'
import { Link } from "react-scroll";
import Counter from 'react-countup'
const Hero = () => {
  const transition = {
    type: 'tween',
    duration: '3'
  }
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero" id='Home'>
      <div className="blur hero-blur" >

      </div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div initial={{ left: mobile ? "150px" : '238px' }}
            whileInView={{ left: '8px' }}
            transition={transition}>


          </motion.div>
          <span>The Best Fitness Club in a Town</span>
        </div>
        <div className="hero-text">
          <div>
            <span className='stroke-text'>SHAPE </span>
            <span>YOUR </span>
          </div>
          <div>
            <span>IDEAL BODY</span>
          </div>
          <div>
            <span>In here we will help you to shape and build your ideal body and live up your life to fullest👉</span>
          </div>


        </div>
        <div className="figures">
          <div>
            <span><Counter start={0} end={140}prefix='+'></Counter>
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
            <Counter start={800} end={978}prefix='+'></Counter>
            </span>
            <span>MEMBERS JOINED</span>
          </div>
          <div>
            <span>
            <Counter start={0} end={50}prefix='+'></Counter>
            </span>
            <span>FITNESS PROGRAMS</span>
          </div>

        </div>
        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="right-h">
        <button className='btn'>
          <Link
            to='Join'
            span={true}
            smooth={true}
          >Join Now</Link>
        </button>

        <motion.div
          initial={{ left: '-8px' }}
          whileInView={{ left: '180px' }}
          transition={transition}
          className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span> 116 bpm</span>
        </motion.div>
        <img src={hero_image} alt="" className='hero-image' />
        <motion.img
          initial={{ right: '0px' }}
          whileInView={{ right: '300px' }}
          transition={transition}
          src={Hero_image_back} alt="" className='hero-image-back' />
        <motion.div className="calories"
          initial={{ right: '400px' }}
          whileInView={{ right: '500px' }}
          transition={transition}>
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned </span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>


    </div>
  )
}
export default Hero;
